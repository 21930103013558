<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-prevent-closing
      variant="outline-primary"
    >
      Add coupon
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Add new coupon"
      ok-title="Create"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="couponInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">coupon Details</h5>
              <small class="text-muted"> Enter the coupon Details. </small>
            </b-col>
            <b-col md="6">
              <b-form-group label="Code" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="Code"
                    v-model="coupon.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="coupon Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="coupon type" label-for="coupontype">
                <validation-provider
                  #default="{ errors }"
                  name="coupon type"
                  rules="required"
                >
                  <b-form-select
                    id="coupontype"
                    :state="errors.length > 0 ? false : null"
                    v-model="coupon.discountType"
                    :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="coupon value" label-for="coupontype">
                <validation-provider
                  #default="{ errors }"
                  name="coupon value"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="coupondiscount"
                    :state="errors.length > 0 ? false : null"
                    v-model="coupon.discount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="coupon Valide Times"
                label-for="coupon Valide Times"
              >
                <validation-provider
                  #default="{ errors }"
                  name="coupon  Valide Times"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="discountValideTime"
                    :state="errors.length > 0 ? false : null"
                    v-model="coupon.discountValideTime"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="expire Date" label-for="expire Date">
                <validation-provider
                  #default="{ errors }"
                  name="expire Date"
                  vid="expire Date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="register-expire Date"
                    v-model="coupon.expire"
                    name="register-expire Date"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import couponsApi from "@/apiServices/couponsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["percentage", "price"],
    submittedNames: [],
    coupon: {},
  }),
  methods: {
    createcoupons(couponFormData) {
      couponsApi.createCoupon(couponFormData).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "coupon Created",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createcouponValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.couponInfoRules.validate().then((success) => {
          if (success) {
            this.createcoupons(this.coupon);
            this.$refs["my-modal"].toggle("#toggle-btn");
            this.coupon = {};

            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createcouponValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
