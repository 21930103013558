<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <b-modal
      ref="my-modal-edit-edit"
      modal-class="modal-info"
      hide-footer
      title="update coupon"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="couponInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">coupon Details</h5>
              <small class="text-muted"> Enter the coupon Details. </small>
            </b-col>
            <b-col md="6">
              <b-form-group label="Code" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="Code"
                    v-model="coupon.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="coupon Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="coupon type" label-for="coupontype">
                <validation-provider
                  #default="{ errors }"
                  name="coupon type"
                  rules="required"
                >
                  <b-form-select
                    id="coupontype"
                    :state="errors.length > 0 ? false : null"
                    v-model="coupon.discountType"
                    :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="coupon value" label-for="coupontype">
                <validation-provider
                  #default="{ errors }"
                  name="coupon value"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="coupondiscount"
                    :state="errors.length > 0 ? false : null"
                    v-model="coupon.discount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <userSidebar class="mt-2" @addusers="addusers" />
            <b-col md="12">
              <b-row
                v-if="coupon._id"
                v-for="(user, index) in coupon.students"
                :id="id"
                :key="id"
                class="w-100"
              >
                <b-card class="w-100" v-if="user">
                  <b-row>
                    <b-col md="7">
                      <b-avatar :src="user.student.profileImg" />
                      {{ user.student.name }}
                    </b-col>

                    <b-col md="3">
                      <b-form-group
                        label="Valide Times"
                        label-for="Valide Times"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="coupon  Valide Times"
                          rules="required|numeric"
                          :vid="'register-expairationDate' + user.student._id"
                        >
                          <b-form-input
                            id="discountValideTime"
                            :state="errors.length > 0 ? false : null"
                            v-model="user.discountValideTime"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="danger"
                        class="btn-icon"
                        @click="deleteStudentFromCoupon(index)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <!-- <userCard :user="user"> </userCard> -->
                </b-card>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-form-group label="expire Date" label-for="expire Date">
                <validation-provider
                  #default="{ errors }"
                  name="expire Date"
                  vid="expire Date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="register-expire Date"
                    v-model="coupon.expire"
                    name="register-expire Date"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info">
        <b-col md="4"> </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-secondary"
            block
            @click="hideModal"
          >
            No
          </b-button>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-info"
            block
            @click="toggleModal"
          >
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">update</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import couponsApi from "@/apiServices/couponsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";
import userSidebar from "@/views/users pages/components/usersSidebar.vue";

export default {
  props: ["coupon"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    userSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      options: ["percentage", "price"],

      prams: {
        id: "",
      },
    };
  },
  created() {
    // console.log(this.coupon);
    // this.coupon.imageCover = null;
  },
  methods: {
    deleteStudentFromCoupon(user, index) {
      this.coupon.students.splice(index, 1);
      // this.studentUpdate(user.student);
    },
    addusers(students) {
      console.log(students[0]);
      students.forEach((element1) => {
        if (
          this.coupon.students.findIndex(
            (element) => element?.student?._id == element1._id
          ) === -1
        ) {
          this.coupon.students.push({
            student: element1,
            discountValideTime: 1,
          });
        }
      });
    },
    updatecoupon() {
      let tempCoupon = structuredClone(this.coupon);
      tempCoupon.students = [];
      for (const element of this.coupon.students) {
        console.log(element);
        tempCoupon.students.push({
          student: element.student._id,
          discountValideTime: element.discountValideTime,
        });
      }
      // this.coupon.students.forEach((element) => {

      // });
      console.log(tempCoupon.students[0] + "asddddd");
      couponsApi.updateCoupon(tempCoupon).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "coupon updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal-edit-edit"].show();
    },
    hideModal() {
      this.$refs["my-modal-edit-edit"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden

      this.updatecoupon();
      this.$refs["my-modal-edit-edit"].toggle("#toggle-btn");
    },
  },
};
</script>
